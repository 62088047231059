(function () {
    'use strict';

    function templateDataService(TEMPLATE_DATA_SERVICE_URL, $http, userService) {

        // Create template data factory contructor
        var TemplateData = {
            getTemplateData: getTemplateData
        }

        return TemplateData;


        ////////////////////////////////////////


        function getTemplateData(templateDataId) {
            return $http.get(TEMPLATE_DATA_SERVICE_URL, {
                params: {
                    id: templateDataId,
					lang: userService.lang
                }
            });
        }
    }

    templateDataService.$inject = ['TEMPLATE_DATA_SERVICE_URL', '$http', 'userService'];

    angular
        .module('dssmith')
        .factory('templateDataService', templateDataService);
})();

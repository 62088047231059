interface JQuery {
    __chartist__: any;
}

(function () {
    'use strict';

    function chartController($timeout, chartFactory) {

        // Store a reference to the controller.
        var vm = this;

        //
        // Bindable members
        //

        // Chart Data Object
        vm.chartData;
        // Chart Event Object
        vm.chartEvents = {};
        // Chart Options Object
        vm.chartOptions = {};
        // Get Chart Function
        vm.getChart = getChart;
        // Set Charts Function
        vm.setChartEvents = setChartEvents;
        // Activate the controller functions on controller on init
        vm.activate = activate;


        ////////////////////////////////////////


        function activate(chartId) {
            getChart(chartId);
            setChartEvents();
        }

        // Get a specific chart
        function getChart(chartId) {
            return chartFactory.getChart(chartId)
                .then(function(response) {
                    vm.chartData = response.data.ChartData;
                    vm.chartOptions = response.data.ChartOptions;
                    // If the ChartPlugin object exists
                    if (!!response.data.ChartPlugins) {
                        vm.chartOptions.plugins = eval(response.data.ChartPlugins);
                    }
                })
                .catch(function (error) {
                    console.error('Could not get the chart: ', error);
                });
        }

        // Set chart events
        function setChartEvents() {
            return vm.chartEvents = {
                draw: function (obj) {
                    if (obj.type === 'line' || obj.type === 'area') {
                        obj.element.animate({
                            d: {
                                begin: obj.index,
                                dur: 800,
                                from: obj.path.clone().scale(1, 0).translate(0, obj.chartRect.height()).stringify(),
                                to: obj.path.clone().stringify(),
                                easing: Chartist.Svg.Easing.easeOutQuint
                                //easeInOutBack
                            }
                        });
                    }

                    if (obj.type === 'point') {
                        obj.element.animate({
                            y1: {
                                begin: obj.index,
                                dur: 800,
                                from: obj.axisY.chartRect.height(),
                                to: obj.y,
                                easing: Chartist.Svg.Easing.easeOutQuint
                            },
                            y2: {
                                begin: obj.index,
                                dur: 800,
                                from: obj.axisY.chartRect.height(),
                                to: obj.y,
                                easing: Chartist.Svg.Easing.easeOutQuint
                            }
                        });
                    }

                    if (obj.type === 'bar') {
                        // Set the bar with based on the total number of bars in the series
                        var barWidth = 100 / obj.axisX.ticks.length - 2; // -2 to make some space between the bars
                        obj.element._node.style.strokeWidth = barWidth + '%';
                        // Animate tha bars
                        obj.element.animate({
                            y2: {
                                begin: obj.index,
                                dur: 800,
                                from: obj.y1,
                                to: obj.y2,
                                easing: Chartist.Svg.Easing.easeOutQuint
                            }
                        });
                    }

                    if (obj.type === 'slice') {
                        // Get the total path length in order to use for dash array animation
                        var pathLength = obj.element._node.getTotalLength();
                        // Set a dasharray that matches the path length as prerequisite to animate dashoffset
                        obj.element.attr({
                            'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
                        });

                        // Create animation definition while also assigning an ID to the animation for later sync usage
                        var animationDefinition = {
                            'stroke-dashoffset': {
                                id: 'anim' + obj.index,
                                dur: 800,
                                from: -pathLength + 'px',
                                to:  '0px',
                                easing: Chartist.Svg.Easing.easeOutQuint,
                                // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
                                fill: 'freeze'
                                }
                        };

                        /*
                        For donuts, if we have more than 1 slice and if we want the animation of
                        each slice to happen right after the previous one, we need to uncomment this code.
                        Now all slices will animate att the same time. This gives a pie chart, which is essentially
                        a donut without the hole, a nicer feel when rendering on the screen.

                        // If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
                        if (obj.index !== 0) {
                            animationDefinition['stroke-dashoffset'].begin = 'anim' + (obj.index - 1) + '.end';
                        }

                        // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
                        obj.element.attr({
                            'stroke-dashoffset': -pathLength + 'px'
                        });
                        */


                        // We can't use guided mode as the animations need to rely on setting begin manually
                        // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
                        obj.element.animate(animationDefinition, false);
                    }
                }
            };
        }
    }

    chartController.$inject = ['$timeout', 'chartFactory'];

    angular
        .module('dssmith')
        .controller('chartController', chartController);
})();

declare var define: any;
declare var exports: any;
declare var module: any;

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], function () {
            return (root.returnExportsGlobal = factory());
        });
    } else if (typeof exports === 'object') {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like enviroments that support module.exports,
        // like Node.
        module.exports = factory();
    } else {
        root['Chartist.plugins.ctTooltips'] = factory();
    }
}(this, function () {

    /**
    * Chartist.js plugin to display a tooltip
    *
    */
    /* global Chartist */
    (function(window, document, Chartist) {
        'use strict';

        Chartist.plugins = Chartist.plugins || {};
        Chartist.plugins.ctTooltips = function(options) {

            return function ctTooltips(chart) {
                const globalYOffset = 10;
                // Default tooltip area trigger
                var tooltipArea = 'ct-point';
                var currentStickyTarget;
                if (chart instanceof Chartist.Bar) {
                    tooltipArea = 'ct-bar';
                }   else if (chart instanceof Chartist.Pie) {
                    if (chart.options.donut) {
                        return false;
                    } else {
                        tooltipArea = 'ct-slice-pie';
                    }
                }

                var chartContainer = chart.container;
                var tooltip = chartContainer.querySelector('.ct-tooltip');
                if (!tooltip) {
                    tooltip = document.createElement('div');
                    tooltip.className = (!options.tooltipClass) ? 'ct-tooltip' : options.tooltipClass;
                    if (!options.appendToBody) {
                        chartContainer.appendChild(tooltip);
                    } else {
                        document.body.appendChild(tooltip);
                    }
                }

                var height = 0;
                var width = 0;

                hide(tooltip);

                on('click', chartContainer, tooltipArea, function(e) {
                    currentStickyTarget = e.target;
                    addSticky(currentStickyTarget, tooltip);
                });

                on('mouseover', chartContainer, tooltipArea, function(e) {
                    removeSticky(currentStickyTarget, tooltip);
                    populateTooltip(e);
                });

                on('mouseout', chartContainer, tooltipArea, function (e) {
                    if (e.toElement.className !== 'ct-tooltip tooltip-show') {
                        hide(tooltip);
                    }
                });

                function populateTooltip(e) {
                    var target = e.target,
                        tooltipText = '',
                        meta = target.getAttribute('ct:meta') || '',
                        value = target.getAttribute('ct:value');

                    if (meta) {
                        tooltipText += '<span class="tooltip-meta">' + meta + ':</span> ';
                    }

                    // Vaue prefix
                    if (options.valuePrefix !== undefined) {
                        tooltipText += options.valuePrefix;
                    }

                    // TODO: Lägg till tusenavskiljare för value
                    tooltipText += value;

                    // Vaue sufix
                    if (options.valueSufix !== undefined) {
                        tooltipText += options.valueSufix;
                    }

                    tooltip.innerHTML = tooltipText;

                    setPosition(e);
                    show(tooltip);

                    height = tooltip.offsetHeight;
                    width = tooltip.offsetWidth;
                }

                function setPosition(e) {

                    height = height || tooltip.offsetHeight;
                    width = width || tooltip.offsetWidth;

                    if (chart instanceof Chartist.Bar ||
                        chart instanceof Chartist.Line ||
                        chart instanceof Chartist.Pie) {

                        var xPos = 0;
                        var yPos = 0;

                        // Elements with x- and y-attributes
                        if (chart instanceof Chartist.Bar ||
                            chart instanceof Chartist.Line) {

                            xPos = parseInt(e.target.getAttribute('x2'));
                            yPos = parseInt(e.target.getAttribute('y2'));

                        } else if (chart instanceof Chartist.Pie) {

                            // For path elements get the bounding box
                            var bBox = e.target.getBBox();
                            xPos = bBox.x;
                            yPos = bBox.y;

                        }

                        if (options.appendToBody) {
                            // TODO
                            // console.log(chartContainer.getBoundingClientRect());
                            // xPos = parseInt(e.target.getAttribute('x2')) + e.target.offsetLeft;
                            // yPos = parseInt(e.target.getAttribute('y2')) + e.target.offsetTop;
                            // tooltip.style.top = yPos + options.tooltipOffset.y + 'px';
                            // tooltip.style.left = xPos + 'px';
                        } else {
                            tooltip.style.top = yPos + globalYOffset + 'px';
                            tooltip.style.left = xPos - width/2 + 'px';
                        }

                        // tooltip.style.top = yPos + options.tooltipOffset.y + 'px';
                        // tooltip.style.left = xPos - width + 'px';

                        // tooltip.style.top = (event.layerY || event.offsetY) - height + options.tooltipOffset.y + 'px';
                        // tooltip.style.left = (event.layerX || event.offsetX) - width / 2 + options.tooltipOffset.x + 'px';

                    }
                }

            };
        };

        function on(event, chartContainer, selector, callback) {
            chartContainer.addEventListener(event, function (e) {
                if (!selector || hasClass(e.target, selector)) {
                    callback(e);
                }
            });
        }

        function show(element) {
            element.classList.add('tooltip-show');
        }

        function hide(element) {
            element.classList.remove('tooltip-show');
        }

        function addSticky(target, element) {
            target.classList.add('target-sticky');
            element.classList.add('tooltip-sticky');
        }

        function removeSticky(target, element) {
            if (typeof target !== 'undefined') {
                target.classList.remove('target-sticky');
                element.classList.remove('tooltip-sticky');
            }
        }

        function hasClass(element, className) {
            return (' ' + element.getAttribute('class') + ' ').indexOf(' ' + className + ' ') > -1;
        }

        function next(element, className) {
            do {
                element = element.nextSibling;
            }
            while (element && !hasClass(element, className));
            return element;
        }

        function text(element) {
            return element.innerText || element.textContent;
        }



    }(window, document, Chartist));

    return Chartist.plugins.ctTooltips;

}));

((): void => {
	'use strict';

    angular.module('dssmith').service('userService', function() {
        return {
            lang: angular.element('html').attr('lang')
        }
    });

})();

declare var ga: any;
declare var SYS: any;

module DSSmith.Services {
    export class GoogleAnalyticsService {
        trackEvent(category: string, name: string, label: string) {
            if (!SYS.isDebug) {
                ga(() => {
                    var trackers = ga.getAll();
                    for (var i = 0; i < trackers.length; ++i) {
                        ga(trackers[i].get('name') + '.send', 'event', category, name, label, 1);
                    }
                });
            }
        }
    }

    angular
        .module('dssmith')
        .service('GoogleAnalyticsService', GoogleAnalyticsService);

}

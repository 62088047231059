(function () {
    'use strict';

    function chartFactory(CHARTS_URL, $http) {

        // Create chart factory contructor
        var Charts = {
            getChart: getChart
        }

        return Charts;


        ////////////////////////////////////////


        function getChart(chartId) {
            return $http.get(CHARTS_URL, {
                params: {
                    id: chartId
                }
            });
        }
    }

    chartFactory.$inject = ['CHARTS_URL', '$http'];

    angular
        .module('dssmith')
        .factory('chartFactory', chartFactory);
})();

/// <reference path="references.ts" />

((): void => {
    angular
        .module('dssmith', [
            'angular-chartist',
            'ngSanitize',
            'geolocation',
            'rzModule',
            'ui.bootstrap.datetimepicker',
            'LocalStorageModule',
            'checklist-model'
        ])
        .constant('CHARTS_URL', '/InteractiveData/GetChart')
        .constant('SUSTAINABILITY_DATA_SERVICE_URL', '/api/sustainability')
        .constant('TEMPLATE_DATA_SERVICE_URL', '/TemplateData/GetTemplateData')
        .constant('MEDIA_QUERY', {
                'small': 480
        });
})();

//
// Resize window event
//
(function () {
	'use strict';

	function resizeWindow($window, $document) {
		return {
			restrict: 'A',
			compile: function($element, $attributes) {
				return {
					pre: function($scope, $element, $attributes) {
						angular.element($window).on('resize.resize', function(e) {
							var w = $window,
								d = $document[0],
								element = d.documentElement,
								g = d.getElementsByTagName('body')[0],
								x = w.innerWidth || element.clientWidth || g.clientWidth,
								y = w.innerHeight || element.clientHeight|| g.clientHeight;

							$scope.$broadcast('resize::resizeWindow', {
								innerWidth: x,
								innerHeight: y
							});
						});
					}
				}
			}
		}
	}

	resizeWindow.$inject = ['$window', '$document'];

	angular
		.module('dssmith')
		.directive('resizeWindow', resizeWindow);
})();

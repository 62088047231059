(function () {
    'use strict';

    function lightboxController(MEDIA_QUERY, $scope, $window, $timeout, lightboxService, templateDataService) {

        // Store a reference to the controller.
        var vm = this;

        //
        // Bindable members
        //

        // Open lightbox function
        vm.openLightbox = openLightbox;
        // Get lightbox function
        vm.getLightbox = getLightbox;
        // Activate the controller functions on controller on init
        vm.activate = activate;

        // State flags
        vm.uiState = {
            isLightboxLoading: false
        }

        ////////////////////////////////////////


        // Window properties object
        var win = {
            width: 0,
            height: 0
        };

        // Set uiState flag for the screensize; small screen true/false
        $scope.$on('resize::resizeWindow',
            function(e, args) {
                win.width = args.innerWidth;
                win.height = args.innerHeight;
            }
        );

        angular.element($window).trigger('resize.resize');

        function activate(id, controller, templateUrl) {
            vm.lightboxId = id;
            vm.controller = controller;
            vm.templateUrl = templateUrl;
        }

        function openLightbox(extendedData = null, $event)  {
            

            // Don't open lightboxes on small screens
            if (win.width <= MEDIA_QUERY.small) {
                return false;
            }

            if (vm.uiState.isLightboxLoading) {
                return false;
            }
            vm.uiState.isLightboxLoading = true;
            vm.getLightbox(vm.lightboxId, vm.controller, vm.templateUrl, extendedData);
        }

        // Get a specific lightbox
        function getLightbox(id, controller, template, extendedData) {
            return templateDataService.getTemplateData(id)
                .then(function (response) {

                    if (!response.data.length) {
                        return;
					}
                    vm.lightboxData = response.data;

                    // Get lightbox template here
                    lightboxService.showLightbox({
                        templateUrl: vm.templateUrl,
                        controller: vm.controller,
                        controllerAs: 'vm',
                        inputs: {
                            data: vm.lightboxData,
                            extendedData: extendedData
                        }
                    }).then(function(lightbox) {
                        vm.uiState.isLightboxLoading = false;
                        lightbox.close.then(function(result) {});
                    });

                })
                .catch(function (error) {
                    console.error('Could not get data: ', error);
                });
        }
    }

    lightboxController.$inject = ['MEDIA_QUERY', '$scope', '$window', '$timeout', 'lightboxService', 'templateDataService'];

    angular
        .module('dssmith')
        .controller('lightboxController', lightboxController);
})();

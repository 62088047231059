interface Element {
    __chartist__: any;
}

(function() {
    'use strict';

    function tabsController(MEDIA_QUERY, $scope, $element, $window, $timeout) {

        // Store a reference to the controller.
        var vm = this;
        vm.sliders = [];
        //
        // Bindable members
        //

        // Store state flags for views etc.
        vm.uiState = {
            activeTabIndex: 0,
            isTabsActive: false,
            isTabsVisible: true,
            isMobile: false
        };
        // Tab Label
        vm.activeTabLabel = '';
        // Toggle tabs function
        vm.showTab = showTab;
        // Scroll to tab function
        vm.scrollToTab = scrollToTab;
        // Activate the controller functions on controller in the init directive
        vm.activate = activate;


        ////////////////////////////////////////


        // Tab Label
        var noActiveTabLabel = '';
        // Window properties object
        var win = {
            width: 0,
            height: 0
        };

        function activate(activeTabIndex, noActiveTabLabel) {
            init(activeTabIndex, noActiveTabLabel);
            windowResize();
        }


        function init(activeTabIndex, noTabLabel) {
            // Set the active tab index
            vm.uiState.activeTabIndex = activeTabIndex;
            // Store the No Active Tab Label
            noActiveTabLabel = noTabLabel;
            // Set the Active Tab Label. 0 = no active tab
            if (vm.uiState.activeTabIndex === 0) {
                vm.activeTabLabel = noActiveTabLabel;
            } else {
                vm.activeTabLabel = $element.find('.tabs li').eq(vm.uiState.activeTabIndex - 1).text();
            }
        }

        // Set uiState flag for the screensize; small screen true/false
        function windowResize() {
            $scope.$on('resize::resizeWindow',
                function(e, args) {
                    win.width = args.innerWidth;
                    win.height = args.innerHeight;
                    $timeout(function() {
                            if (win.width <= MEDIA_QUERY.small) {
                                vm.uiState.isMobile = true;
                                vm.uiState.isTabsVisible = false;
                            } else {
                                vm.uiState.isMobile = false;
                                vm.uiState.isTabsVisible = true;
                            }
                        },
                        0);
                }
            );
            angular.element($window).trigger('resize.resize');
        }

        // Toggle tabs
        function showTab($event, $index) {
            angular.forEach(this.sliders,
            (item) => {
                item.update();
                });
            vm.uiState.activeTabIndex = $index;

            // Allways hide the tabs after selecting one on a mobile screen
            if (vm.uiState.isMobile) {
                vm.uiState.isTabsVisible = false;
            }

            // If we click a tab, set the active tab label to the text of the currently active tab (visible on small/mobile screens).
            // Otherwise set the label to "no avive tab".
            if (vm.uiState.activeTabIndex === 0) {
                vm.activeTabLabel = noActiveTabLabel;
            } else {
                vm.activeTabLabel = angular.element($event.currentTarget).text();
            }

            // Charts that were hidden when rendered on the page needs to be updated to rerender knowing its container boundaries.
            // For now we just find all charts and call update on all of them.
            $timeout(function() {
                angular.element('[chartist]').each(function (i, elm) {
                    if (elm.__chartist__) {
                        elm.__chartist__.update();
                    }
                });
            }, 0);
        }


        // Scroll to element with id
        function scrollToTab($event, activeTabIndex, targetId) {
            $event.preventDefault();

            // Set the active tab index
            vm.uiState.activeTabIndex = activeTabIndex;
            vm.activeTabLabel = $element.find('.tabs li').eq(vm.uiState.activeTabIndex - 1).text();

            // Only do scroll if we are on a mobile/small screen
            if (win.width <= MEDIA_QUERY.small) {
                var $targetElement = angular.element(document.getElementById(targetId));
                $('html, body').animate({
                    scrollTop: $targetElement.offset().top - 20
                }, 300);
            }
        }
    }

    tabsController.$inject = ['MEDIA_QUERY', '$scope', '$element', '$window', '$timeout'];

    angular
        .module('dssmith')
        .controller('tabsController', tabsController);
})();

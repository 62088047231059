(function () {
    'use strict';

    function lightboxMediaCarouselController($scope, $document, data, extendedData, close, $sce) {

        // Store a reference to the controller.
        var vm = this;

        // Trusted sources
        $scope.trustSrc = function (src) {
            return $sce.trustAsResourceUrl(src);
        }

        //
        // Bindable members
        //

        // Slides object
        vm.slides = data;
        // // Visible slide index. Default 0
		vm.currentIndex = 0;
        // // Go to the next slide function
        vm.goToNext = goToNext;
        // // Go to the previous slide function
        vm.goToPrev = goToPrev;
        // // Go to specific slide function
        vm.goToIndex = goToIndex;


        ////////////////////////////////////////

        if (extendedData !== null) {
            vm.currentIndex = extendedData;
        }

        // Close the lightbox
        $scope.close = close;

        // Bind keyboard shortcuts for navigating the carousel left/right and to close the lightbox.
		$document.on('keyup.lightbox.carousel', function(e) {
			if (e.keyCode === 37) {
				$scope.$apply(function() {
					vm.currentIndex > 0 ? vm.currentIndex-- : vm.currentIndex = vm.slides.length - 1;
				});
			} else if (e.keyCode === 39) {
				$scope.$apply(function(){
					vm.currentIndex < vm.slides.length - 1 ? vm.currentIndex++ : vm.currentIndex = 0;
				});
			}
		});

        // Next slide
		function goToNext() {
		    vm.currentIndex < vm.slides.length - 1 ? vm.currentIndex++ : vm.currentIndex = 0;
		}

		// Previous slide
	    function goToPrev() {
			vm.currentIndex > 0 ? vm.currentIndex-- : vm.currentIndex = vm.slides.length - 1;
		}

		// Index slide
		function goToIndex(index) {
			vm.currentIndex = index;
		}

        // Detect changes on current slide index
        $scope.$watch('vm.currentIndex', function () {
            if (vm.slides) {
                vm.slides.forEach(function (slide) {
                    // Make all slides invisible
                    slide.visible = false;
                });
                vm.slides[vm.currentIndex].visible = true;
            }
		});
    }

    lightboxMediaCarouselController.$inject = ['$scope', '$document', 'data', 'extendedData', 'close', '$sce'];

    angular
        .module('dssmith')
        .controller('lightboxMediaCarouselController', lightboxMediaCarouselController);
})();
